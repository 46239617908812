/* Dashboard.module.css */
html[data-admin-dashboard="true"],
html[data-admin-dashboard="true"] body,
html[data-admin-dashboard="true"] #root {
  max-width: 1000em !important;
  margin: auto;
}

html[data-admin-dashboard="true"] header {
  max-width: 1000em !important;
  margin: auto;
  right: auto !important;
  left: auto !important;
}






