@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 400;
  src: local("SourceSansPro"), local("SourceSansPro"),
    url("./fonts/SourceSansPro-Regular.otf") format("opentype");
}

body {
  margin: 0;
  padding: 0;
}

html,
body,
h1,
h2,
h3,
h4,
p,
span,
label,
a {
  font-family: "SourceSansPro" !important;
}

html,
body,
#root {
  height: 100%;
  width: 100%;

}


@media (min-width: 1000px) {
  html,
  body,
  #root {
    max-width: 40em;
    margin: auto;
  }

  header {
    max-width: 40em;
    margin: auto;
    right: auto !important;
  }
}

a,
a:active,
a:hover {
  color: #006bcc;
  text-decoration: none;
}
